import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

export const query = graphql`
  query($slug: String!) {
    contentfulGallery(slug: { eq: $slug }) {
      category {
        name
        gallerySlug
      }
      seo {
        title
        description
        keywords
      }
      id
      name
      slug
      images {
        fluid {
          src
          ...GatsbyContentfulFluid_withWebp
        }
        title
        id
      }
    }
  }
`

const SingleGallery = ({ data }) => {
  const gallery = data.contentfulGallery
  const images = gallery.images.map(image => image.fluid.src)

  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Layout>
      <SEO
        title={gallery.seo?.title || ""}
        description={gallery.seo?.description || ""}
        keywords={gallery.seo?.keywords || ""}
      />
      <section className="inner-intro bg-img-single-service light-color overlay-before parallax-background">
        <div className="container">
          <div className="row title">
            <div className="title_row">
              <h1 data-title={gallery.name}>
                <span>{gallery.name}</span>
              </h1>
              <div className="page-breadcrumb">
                <Link to="/">Home</Link> / <Link to="/gallery/">Gallery</Link> /{" "}
                <Link to={gallery.category.gallerySlug}>
                  {gallery.category.name}
                </Link>{" "}
                / <span>{gallery.name}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="padding ptb-xs-40">
        <div className="container">
          <div className="row pb-30">
            <div className="col-md-12">
              <ul className="container-filter categories-filter">
                <li>
                  <Link to={gallery.category.gallerySlug}>
                    Back to {gallery.category.name}
                  </Link>
                </li>
                <li>
                  <Link to="/gallery/">Back to Gallery</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="row text-center mb-40">
            <div className="col-lg-6 offset-lg-3 sect-title">
              <h2>
                <span>{gallery.name}</span>
              </h2>
              <span className="title_bdr">
                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div className="row container-grid nf-col-3">
            {gallery.images.map((image, index) => (
              <div key={image.id} className="nf-item spacing">
                <div
                  className="item-box"
                  onClick={() => {
                    setPhotoIndex(index)
                    setIsOpen(true)
                  }}
                >
                  <Img
                    fluid={image.fluid}
                    alt={image.title}
                    title={image.title}
                  />
                  <div className="link-zoom">
                    <i className="fa fa-search-plus" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % images.length)
              }
            />
          )}
        </div>
      </section>
    </Layout>
  )
}

export default SingleGallery
